<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Новый входящий платеж</p>
          <div>
            <el-button type="success" size="medium" plain>Сохранить</el-button>
            <el-button type="warning" size="medium" plain>Закрыть</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item >
                            <div class="pay_data-stlye d-flex align-center">
                                <span class="input--label d-block mb-0 line-h-24 title_data">Платеж № 51 от</span>
                                <el-date-picker
                                    class="w-100"
                                    size="medium"
                                    v-model="form.value1"
                                    type="datetime"
                                    placeholder="Select date and time">
                                </el-date-picker>
                            </div>
                        </el-form-item> 
                    </el-col>
                    <!-- end-col -->
                </el-row>
                <el-row :gutter="20">

                    <el-col :span="8">
                        <el-form-item>
                                <span class="input--label d-block mb-0 line-h-24">Клиент</span>
                                <el-select class="w-100" v-model="form.region" size="medium" placeholder="Клиент">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                        </el-form-item>
                        <el-form-item>
                                <span class="input--label d-block mb-0 line-h-24">Тип оплаты</span>
                                <el-select class="w-100" v-model="form.region" size="medium" placeholder="Тип оплаты">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                        </el-form-item>
                        <el-form-item>
                                <span class="input--label d-block mb-0 line-h-24">Договор</span>
                                <el-select class="w-100" v-model="form.region" size="medium" placeholder="Договор">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                        </el-form-item>  
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="8">
                        <el-form-item>
                                <span class="input--label d-block mb-0 line-h-24">Сумма</span>
                                <el-input
                                    placeholder="Сумма"
                                    v-model="form.input"
                                    size="medium"
                                ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="input--label d-block mb-0 line-h-24">Валюта</span>
                            <el-select class="w-100" v-model="form.region" size="medium" placeholder="Валюта">
                                <el-option label="Zone one" value="shanghai"></el-option>
                                <el-option label="Zone two" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>   
                    </el-col>
                    <!-- end-col -->

                     <el-col :span="8">
                        <el-form-item>
                            <span class="input--label d-block mb-0 line-h-24">Счет</span>
                            <el-select class="w-100" v-model="form.region" size="medium" placeholder="Счет">
                                <el-option label="Zone one" value="shanghai"></el-option>
                                <el-option label="Zone two" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>  

                        <el-form-item>
                            <span class="input--label d-block mb-0 line-h-24">Комментарий</span>
                            <el-input type="textarea" v-model="form.desc"></el-input>
                        </el-form-item>      
                    </el-col>
                    <!-- end-col -->

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        form:{}
    };
  },
 
  components: {},
};
</script>
