<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> Платежи   </div>
              <div class="block-sarche">
                <div class="header__search">
                    <el-date-picker
                    v-model="value1"
                    :class="mode ? 'input__day' : 'input__night'"
                     size="small"
                    type="date"
                    class="mr-3"
                    placeholder="От">
                    </el-date-picker>
                        <el-date-picker
                    v-model="value1"
                     size="small"
                     :class="mode ? 'input__day' : 'input__night'"
                    type="date"
                    placeholder="До">
                    </el-date-picker>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-button size="small" class="btu-color" @click="createModal = true">
              Исходящий платеж
            </el-button>
            <el-button size="small" class="btu-color" @click="createModal = true">
              Входящий платеж
            </el-button>
            <el-button size="small" class="btu-color">
             Excel
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->


        <div class="w-100 d-flex align-center  justify-center">
            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--light" :class="mode ? 'price__day' : 'price__night blue__night'">
                    Общая сумма Входящий: 3 286 251 000 Сум
                </span>
            </span>
            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--success el-tag--light" :class="mode ? 'price__day' : 'price__night green__night'">
                    Общая сумма Исходящий: 99 704 000 Сум
                </span>
            </span> 
        </div>

        <div class="card-table-header table-crm-smart">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered">
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>

                    <th v-if="columns.customer.show">
                        {{ columns.customer.title }}
                    </th>

                    <th v-if="columns.paymentType.show">
                        {{ columns.paymentType.title }}
                    </th>
                    

                    <th v-if="columns.typePayment.show">
                        {{ columns.typePayment.title }}
                    </th>

                    <th v-if="columns.calculation.show">
                        {{ columns.calculation.title }}
                    </th>

                    <th v-if="columns.accounts.show">
                        {{ columns.accounts.title }}
                    </th>

                    <th v-if="columns.time.show">
                        {{ columns.time.title }}
                    </th>

                    <th v-if="columns.sum.show">
                        {{ columns.sum.title }}
                    </th>

                     <th v-if="columns.currency.show">
                        {{ columns.currency.title }}
                    </th>

                    <th v-if="columns.created_at.show">
                        {{ columns.created_at.title }}
                    </th>

                    <th v-if="columns.updated_at.show">
                        {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.id"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.id.title"
                        class="id_input"
                        ></el-input>
                    </th>
                    
                    <th v-if="columns.customer.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.customer"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.customer.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.paymentType.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.paymentType"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.paymentType.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.typePayment.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.calculation.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.calculation"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.calculation.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.accounts.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.accounts"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.accounts.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.time.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.time"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.time.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.sum.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.sum"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.sum.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.currency.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.currency"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.currency.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.created_at.show">
                        <el-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                        size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th v-if="columns.updated_at.show">
                        <el-date-picker
                        :placeholder="columns.updated_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.updated_at"
                        size="mini"
                        >
                        </el-date-picker>
                    </th>

                <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="payments in list" :key="payments.id" class="cursor-pointer">

                    <td v-if="columns.id.show">
                        {{ payments.id }}
                    </td>

                    <td v-if="columns.customer.show">
                        {{ payments.customer }}
                    </td>

                    <td v-if="columns.paymentType.show">
                        {{ payments.paymentType }}
                    </td>

                    <td v-if="columns.typePayment.show">
                        {{ payments.typePayment }}
                    </td>

                    <td v-if="columns.calculation.show">
                        {{ payments.calculation }}
                    </td>

                    <td v-if="columns.accounts.show">
                        {{ payments.accounts }}
                    </td>

                    <td v-if="columns.time.show">
                        {{ payments.time }}
                    </td>

                    <td v-if="columns.sum.show">
                        {{ payments.sum }}
                    </td>

                    <td v-if="columns.currency.show">
                        {{ payments.currency }}
                    </td>
                
                    <td v-if="columns.created_at.show">
                        {{ payments.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                        {{ payments.updated_at }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <el-dropdown :hide-on-click="false" class="table-bottons-right">
                        <span class="el-dropdown-link more_icons">
                            <i class="el-icon-arrow-down el-icon-more-outline"></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="dropdown-menumy-style"
                        >
                            <el-dropdown-item icon="el-icon-edit">
                            Изменить
                            </el-dropdown-item>
                            <el-dropdown-item icon="el-icon-delete">
                            Удалить
                            </el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </td>
                </tr>
            </transition-group>
            </table>
            <div class="my___pagination">
                <crm-pagination
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  :visible.sync="createModal" size="90%">
                <div>
                    <crm-create> </crm-create>
                </div>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import {mapGetters} from 'vuex'

export default {
    name: "payments",
    components: {
        Pagination,
        CrmCreate,
    },

    data() {
        return {
            createModal: false,
            currentPage2: 5,
            checkList: [],
            form: {},
            sarcheBlocks: "",
            filterForm: {
                id: "",
                customer: "",
                paymentType:"",
                typePayment: "",
                calculation: "",
                accounts: "",
                time: "",
                sum: "",
                currency: "",
                created_at: "",
                updated_at: "",
            },
            list: [
                {
                    id: "1",
                    customer: "Продажа",
                    paymentType:"Входящий платеж",
                    typePayment: "Продажа",
                    calculation: "Наличный",
                    accounts: "СЧЁТ №2",
                    time: "2021-05-17 10:32",
                    sum: "250 000 000",
                    currency: "сум",
                    created_at: "02.05.1995",
                    updated_at: "12.12.2020",
                },
                
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                customer: {
                    show: true,
                    title: "Клиент",
                    sortable: true,
                    column: "customer",
                },

                paymentType: {
                    show: true,
                    title: "Тип платежа",
                    sortable: true,
                    column: "paymentType",
                },

                typePayment: {
                    show: true,
                    title: "Тип оплаты",
                    sortable: true,
                    column: "typePayment",
                },

                calculation: {
                    show: true,
                    title: "Вид расчета",
                    sortable: true,
                    column: "calculation",
                },

                accounts: {
                    show: true,
                    title: "Счета",
                    sortable: true,
                    column: "accounts",
                },

                time: {
                    show: true,
                    title: "Время",
                    sortable: true,
                    column: "time",
                },
                
                sum: {
                    show: true,
                    title: "Сумма",
                    sortable: true,
                    column: "sum",
                },


                currency: {
                    show: true,
                    title: "Валюта ",
                    sortable: true,
                    column: "currency",
                },

                created_at: {
                    show: true,
                    title: "Дата создания",
                    sortable: true,
                    column: "created_at",
                },

                updated_at: {
                    show: true,
                    title: "Дата изменена ",
                    sortable: true,
                    column: "updated_at",
                },

                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            mode: "MODE"
        }),
    }
};
</script>

